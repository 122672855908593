const isAlphaNumeric = (str: string): boolean => {
    let code;
    let i;
    let len;

    for (i = 0, len = str.length; i < len; i += 1) {
        code = str.charCodeAt(i);
        if (
            !(code > 31 && code < 58) && // symbols and numeric
            !(code > 64 && code < 91) && // upper alpha
            !(code > 96 && code < 123) // lower alpha
        ) {
            return false;
        }
    }
    return true;
};

const isNumeric = (str: string): boolean => {
    let code;
    let i;
    let len;

    for (i = 0, len = str.length; i < len; i += 1) {
        code = str.charCodeAt(i);
        if (
            !(code > 47 && code < 58) // numeric
        ) {
            return false;
        }
    }
    return true;
};

export const validateName = (name: string | null, testAlphaNumeric?: boolean): string | true => {
    if (name && testAlphaNumeric && !isAlphaNumeric(name)) {
        return 'Please enter name in English';
    }
    if (!name) {
        return 'Missing name';
    }
    if (name.length < 3) {
        return 'Invalid or partial name';
    }
    if (name.split(' ').length < 2) {
        return 'Not full name';
    }

    return true;
};

export const validateAddress = (name: string | null, testAlphaNumeric?: boolean): string | true => {
    if (name && testAlphaNumeric && !isAlphaNumeric(name)) {
        return 'Please enter address in English';
    }
    if (!name) {
        return 'Missing address';
    }

    return true;
};

export const validatePhone = (phone: string | null): string | true => {
    if (!phone) {
        return 'Missing phone number';
    }
    if (phone.length !== 10 || !isNumeric(phone) || phone.charAt(0) !== '0') {
        return 'Invalid phone number; Example format: 0771234567';
    }

    return true;
};

export const validateEmail = (email: string | null): string | true => {
    if (!email) {
        return 'No email provided';
    }
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }

    return 'Incorrect email';
};

export const validateDate = (date: string | null): string | true => {
    if (!date) {
        return 'Please enter a valid date';
    }
    if (
        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
            date,
        )
    ) {
        return true;
    }

    return 'Invalid date. Please use dd/mm/yyyy format. Eg: 20/10/2019';
};

export const validateDateYYYYMMDD = (date: string | null): string | true => {
    if (!date) {
        return 'Please enter a valid date';
    }
    if (/^\d{4}\/\d{2}\/\d{2}$/.test(date)) {
        return true;
    }

    return 'Invalid date. Please use YYYY/MM/DD format. Eg: 2019/10/20';
};

export const validatePassword = (password: string | null): string | true => {
    if (!password) {
        return 'No password provided';
    }
    if (password.length < 8) {
        return 'Password too short';
    }

    return true;
};
